import React from 'react';

function RouteNotFound() {
  return (
    <>
      <h1>Route not found</h1>
    </>
  );
}

export default RouteNotFound;
